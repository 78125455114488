import React from 'react'
import { useState } from 'react';
import "./faqs.scss"

const Faqs = () => {
    const [isShown, setIsShown] = useState(false);
    const handleClick = (event) => {
        setIsShown((current) => !current);
    };
    const [isShown1, setIsShown1] = useState(false);
    const handleClick1 = (event) => {
        setIsShown1((current) => !current);
    };
    const [isShown2, setIsShown2] = useState(false);
    const handleClick2 = (event) => {
        setIsShown2((current) => !current);
    };
    const [isShown3, setIsShown3] = useState(false);
    const handleClick3 = (event) => {
        setIsShown3((current) => !current);
    };
    const [isShown5, setIsShown5] = useState(false);
    const handleClick5 = (event) => {
        setIsShown5((current) => !current);
    };
    return (
        <>
            <section className="faqs" id='faqs'>
                <div className="theme-container">
                    <div className="faqs-heading" style={{overflow: "hidden"}}>
                        <div className="left wow animate__animated animate__fadeInLeft animate__fast" >
                            <span className='sub-title'>FAQ</span>
                            <h6 className="title">Frequently Asked Question</h6>
                        </div>
                        <div className="mid"><img src="\assets\line-faqs.svg" alt="img" className='img-fluid' /></div>
                        <div className="right wow animate__animated animate__fadeInRight animate__fast" >
                            <p className="para">If you have any questions, our team is here to help! </p>
                        </div>
                    </div>
                    <div className="bottom-contentfaq" style={{overflow: "hidden"}}>
                        <div className="inner-faq wow animate__animated animate__fadeInUp animate__fast" >
                            <div className="left">
                                <h6 className={isShown1 ? "colorchange" : ""}>Where will the presale of KingKong DOGE take place, and what are the minimum and maximum limits for purchasing during the presale?</h6>
                            </div>
                            {
                                isShown1 && (
                                    <div className="mid">
                                        <p>The presale for KingKong Doge will be held on the Pinksale website, with a minimum buying limit of 0.1 BNB and a maximum of 10 BNB. To participate, simply click <a style={{color: "#FF3701"}} target="_blank" href="https://www.pinksale.finance/launchpad/0xf78d841919a97a559c283ad8e22b38d1f087a408?chain=BSC">here</a> and join the action.</p>
                                    </div>
                                )
                            }
                            <div className="right">
                                <button onClick={handleClick1} className='btn-click'>{isShown1 ? <img src="\assets\minus-icon.svg" alt="img" className='img-fluid'  /> : <> <img src="\assets\plus-icon.svg" alt="img" className='img-fluid '  /> </> }</button>
                            </div>
                        </div>
                        <div className="inner-faq wow animate__animated animate__fadeInUp animate__fast" >
                            <div className="left">
                                <h6 className={isShown2 ? "colorchange" : ""}>When will the KingKong DOGE presale begin?</h6>
                            </div>
                            {
                                isShown2 && (
                                    <div className="mid">
                                        <p>Mark your calendars for the KingKong Doge Presale, starting on the 4th April at 14.00 UTC. Don't miss out on this exciting opportunity — Click <a style={{color: "#FF3701"}} href="https://www.pinksale.finance/launchpad/0xf78d841919a97a559c283ad8e22b38d1f087a408?chain=BSC" target="_blank">here</a> to join the presale now!</p>
                                    </div>
                                )
                            }
                            <div className="right right2">
                                <button onClick={handleClick2} className='btn-click'>{isShown2 ? <img src="\assets\minus-icon.svg" alt="img" className='img-fluid'  /> : <> <img src="\assets\plus-icon.svg" alt="img" className='img-fluid '  /> </> }</button>
                            </div>
                        </div>
                        <div className="inner-faq wow animate__animated animate__fadeInUp animate__fast" >
                            <div className="left">
                                <h6 className={isShown3 ? "colorchange" : ""}>When can we expect KingKong DOGE to be listed on PancakeSwap?</h6>
                            </div>
                            {
                                isShown3 && (
                                    <div className="mid">
                                        <p>KingKong Doge will be listed on Pancakeswap precisely one day after the presale!</p>
                                    </div>
                                )
                            }
                            <div className="right right3">
                                <button onClick={handleClick3} className='btn-click'>{isShown3 ? <img src="\assets\minus-icon.svg" alt="img" className='img-fluid'  /> : <> <img src="\assets\plus-icon.svg" alt="img" className='img-fluid '  /> </> }</button>
                            </div>
                        </div>
                        <div className="inner-faq wow animate__animated animate__fadeInUp animate__fast" >
                            <div className="left">
                                <h6 className={isShown ? "colorchange" : ""}>Is the liquidity locked?</h6>
                            </div>
                            {
                                isShown && (
                                    <div className="mid">
                                        <p>Rest assured, the liquidity for KingKong Doge will remain locked for 100 years, ensuring stability and security for our community. To learn more about the presale and join the action, click <a style={{color: "#FF3701"}} href="https://www.pinksale.finance/launchpad/0xf78d841919a97a559c283ad8e22b38d1f087a408?chain=BSC" target="_blank">Here</a>.</p>
                                    </div>
                                )
                            }
                            <div className="right right2">
                                <button onClick={handleClick} className='btn-click'>{isShown ? <img src="\assets\minus-icon.svg" alt="img" className='img-fluid'  /> : <> <img src="\assets\plus-icon.svg" alt="img" className='img-fluid '  /> </> }</button>
                            </div>
                        </div>
                        <div className="inner-faq wow animate__animated animate__fadeInUp animate__fast" >
                            <div className="left">
                                <h6 className={isShown5 ? "colorchange" : ""}>What are the fees?</h6>
                            </div>
                            {
                                isShown5 && (
                                    <div className="mid">
                                        <p>Please note that there will Be a 6% fee when buying and a 7% fee when selling KingKong Doge tokens. However, the LP tokens generated from these fees will Be used to power the project and future token burns. To learn more about our fees, join our community discussion and check out our <a target="_blank" href="https://t.me/kingkongdogetoken" style={{color:"#FF3701"}}>Telegram</a> Group.</p>
                                    </div>
                                )
                            }
                            <div className="right right2">
                                <button onClick={handleClick5} className='btn-click'>{isShown5 ? <img src="\assets\minus-icon.svg" alt="img" className='img-fluid'  /> : <> <img src="\assets\plus-icon.svg" alt="img" className='img-fluid '  /> </> }</button>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-text">
                        <p>If would like to contact us regarding marketing or partnership offers, please message us at: <span>contact@kingkongdoge.io</span></p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faqs
