import React, { useState, useEffect } from "react";
import "./burning.scss";
import GetBurnedTime from "../../hooks/dataFetchers/timedBurnTimer";
import Cicularbar from "./Cicularbar";
import ProgressBar from "react-bootstrap/ProgressBar";
import TotalSupplyTB from "../../hooks/dataFetchers/totalSupplyTB";
import AccuSupplyTB from "../../hooks/dataFetchers/accuBurnTB";
import Countdown from "react-countdown";

const Burning = () => {
  const [timeshow, settimeshow] = useState(false);
  // console.log("difference we get here is ", timeshow)
  const [timerrr, setTimer] = useState("");

  // var time = new Date(1683105600 * 1000);
  var time = new Date(1681144023 * 1000);
  const { timeBurn } = GetBurnedTime();
  const { totSupply } = TotalSupplyTB();
  const { AccuBurn } = AccuSupplyTB();

  // console.log("time today we have", time)
  function timer() {
    var now = new Date();
    var diff = time.getTime() - now.getTime();
    var timmmees = time.getTime() - now.getTime();
    // console.log("timeeesssss",timmmees)
    // console.log("32e232233",timmmees/1000)
    setTimer(timmmees / 86400000);
    if (diff <= 0) {
      settimeshow(true);
      return;
    }
  }
  setInterval(() => {
    timer();
  }, 17000);
  const getTimerData = async () => {
    try {
      let result = await timeBurn();
    } catch (error) {}
  };
  const [totalSupplii, setTotalSupplii] = useState("");
  const getTotalSupply = async () => {
    try {
      let result = await totSupply();
      setTotalSupplii(result);
    } catch (error) {}
  };
  const [totalAccu, setTotalAccu] = useState("");
  const getAccuSupply = async () => {
    try {
      let result = await AccuBurn();
      setTotalAccu(result);
    } catch (error) {}
  };
  useEffect(() => {
    getTimerData();
    getTotalSupply();
    getAccuSupply();
  }, []);
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      window.location.reload();
      return null;
    } else {
      // Render a countdown
      return (

        <div className="timer-div">
          <h6>The First Burn Starts In</h6>
          <div className="inner-timer">
            <div className="settimer">
              <h5>{days ? days : 0}</h5>
              <p>Days</p>
            </div>
            <div className="settimer">
              <h5>{hours ? hours : 0}</h5>
              <p>Hours</p>
            </div>
            <div className="settimer">
              <h5>{minutes ? minutes : 0}</h5>
              <p>Minutes</p>
            </div>
            <div className="settimer">
              <h5>{seconds ? seconds : 0}</h5>
              <p>Second</p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <section className="burningtoken">
        <img
          src="\assets\bg-shadow\burningshaderight2.png"
          alt="img"
          className="img-fluid shade1"
        />
        <img
          src="\assets\bg-shadow\burningshadeleft1.png"
          alt="img"
          className="img-fluid shade2"
        />
        <span className="shade3"></span>
        <span className="shade4"></span>
        <div className="theme-container">
          <div className="main-heading wow animate__animated animate__fadeInUpBig animate__fast">
            <h6>TOKEN BURNING</h6>
            <h4>The Innovative burning Mechanism</h4>
            <p>
              Leading the crowd through Kingkong Doge double-burning mechanisms
            </p>
          </div>
          
          <div className="new-burning-flow">
            <div className="main-content">
              <div className="upper-content">
                <img
                  src="\assets\onenew.svg"
                  alt="img"
                  className="img-fluid img-onenew"
                />
                <img
                  src="\assets\auto-burn.svg"
                  alt="img"
                  className="img-fluid"
                />
                <div className="text-absvalue">
                  <h6>Timed Burn</h6>
                  <p>
                    The Timed Burn mechanism automatically sends tokens
                    accumulated from taxes to the burn address after a 24-hour
                    period.
                  </p>
                </div>
              </div>
              <div className="bottom-content">
                <div className="row">
                  <div className="col-xl-4">
                    <div className="main-abs-set">
                      {/* <img src="\assets\cricle-yellow.gif" alt="img" className='img-fluid' /> */}
                      <Cicularbar percentage={timerrr} />
                      <Countdown date={time} renderer={renderer}></Countdown>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="right-side">
                      <div className="box-content">
                        <div className="text">
                          {/* //timed burn */}

                          <h6>Current Total Supply</h6>
                          <p>{parseFloat(totalSupplii).toFixed(2)}</p>
                        </div>
                        {/* <div className="bottom-bg-div bottom-bg-div2">
                                                    <div></div>
                                                </div> */}
                        <div className="progressed2 w-100">
                          <ProgressBar
                            now={100}
                            label={`${100}%`}
                            visuallyHidden
                          />
                        </div>
                      </div>
                      <div className="box-content">
                        <div className="text">
                          <h6>Accumulated in Burn Wallet</h6>
                          <p>{totalAccu}</p>
                        </div>
                        {/* <div className="bottom-bg-div bottom-bg-div1">
                                                    <div></div>
                                                </div> */}
                        <div className="progressed1 w-100">
                          <ProgressBar
                            now={totalAccu && totalAccu}
                            label={`${totalAccu && totalAccu}%`}
                            visuallyHidden
                          />
                        </div>
                      </div>
                      <div className="box-content">
                        <div className="text">
                          <h6>New Supply After Burning</h6>
                          <p>
                            {totalSupplii &&
                              totalAccu &&
                              parseFloat(totalSupplii - totalAccu).toFixed(2)}
                          </p>
                        </div>
                        {/* <div className="bottom-bg-div bottom-bg-div3">
                                                    <div></div>
                                                </div> */}
                        <div className="progressed3 w-100">
                          <ProgressBar
                            now={100}
                            label={`${100}%`}
                            visuallyHidden
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sign">
              <img
                src="\assets\plus-sign.png"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="main-content">
              <div className="upper-content">
                <img
                  src="\assets\twonew.svg"
                  alt="img"
                  className="img-fluid img-onenew"
                />
                <img
                  src="\assets\supplyreduction.svg"
                  alt="img"
                  className="img-fluid"
                />
                <div className="text-absvalue">
                  <h6>Supply Reduction</h6>
                  <p>
                    With the Supply Reduction mechanism, the total supply of
                    tokens is decreased through a secondary burn process.
                  </p>
                </div>
              </div>
              <div className="bottom-content">
                <div className="row">
                  <div className="col-xl-4">
                    <div className="main-abs-set">
                      {/* <img src="" alt="img" className='img-fluid' /> */}
                      <Cicularbar percentage={timerrr} />
                      <Countdown date={time} renderer={renderer}></Countdown>
                    </div>
                  </div>
                  <div className="col-xl-8 m-auto">
                    <div className="right-side">
                      <div className="box-content">
                        <div className="text">
                          <h6>Accumulated in Burn Wallet</h6>
                          <p>{totalAccu}</p>
                        </div>
                        <div className="progressed1 w-100">
                          <ProgressBar
                            now={totalAccu && totalAccu}
                            label={`${totalAccu && totalAccu}%`}
                            visuallyHidden
                          />
                        </div>
                        {/* <div className="bottom-bg-div bottom-bg-div1">
                                                    <div></div>
                                                </div> */}
                      </div>
                      <div className="box-content">
                        <div className="text">
                          <h6>New Supply After Burning</h6>
                          <p>
                            {totalSupplii &&
                              totalAccu &&
                              parseFloat(totalSupplii - totalAccu).toFixed(2)}
                          </p>
                        </div>
                        <div className="progressed3 w-100">
                          <ProgressBar
                            now={100}
                            label={`${100}%`}
                            visuallyHidden
                          />
                        </div>

                        {/* <div className="bottom-bg-div bottom-bg-div3">
                                                    <div></div>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Burning;
