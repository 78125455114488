import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Cicularbar = ({ percentage }) => {
    return (
        <>
        {console.log("percentage",percentage)}
            <div style={{ width: '100%' , margin: '0 auto' , maxWidth: '500px' }}>
                <CircularProgressbar
                    value={percentage}
                    // text={`${percentage}%`}
                    styles={buildStyles({
                        strokeLinecap: 'butt',
                        textColor: '#fff',
                        pathColor: '#F6BB08',
                        trailColor: '#27292F',
                    })}
                />
            </div>
        </>
    )
}
export default Cicularbar
