import {timerContract} from "../../utils/contractHelpers";
import useWeb3 from "../useWeb3";
import { useCallback } from "react";
import Environment from "../../utils/environment";

export const TotalSupplyTB =()=>{
    // const {account} = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.TimedBurnContract;
    const contract = timerContract(contractAddress,web3);
    const totSupply = useCallback (
        async() =>{
            console.log("ssssssdadd")
            try {
                const balance = await contract.methods.totalSupply().call()
                const newBal= web3.utils.fromWei(balance, "ether")
                console.log('efds3ds342rf43', newBal)
                // return console.log('321312312332', await contract.methods)
                return newBal
            } catch (error) {
                console.log('uuuuuuuuuuuuuuuuu', error)
                throw error;
            }
        },[contract]
    );
    return { totSupply: totSupply };
}
export default TotalSupplyTB;
