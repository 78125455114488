import React from 'react'
import './joincommunity.scss'

const Joincommunity = () => {
  return (
    <>
        <section className="join" id='joincommunity'>
            <div className="theme-container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="mainjoin" style={{overflow: "hidden"}}>
                            <div className="jointext wow animate__animated animate__fadeInLeft animate__fast" >
                                <h3 className="headjoin">Join the KingKong Doge Community!</h3>
                                {/* <p className="joinpara">
                                Meet the KingKong Doge team, artists and collectors for platform updates, announcements, and more.
                                </p> */}
                            </div>
                            <div className="joinbtn wow animate__animated animate__fadeInRight animate__fast" >
                                <a href="https://t.me/kingkongdogetoken" target="_blank" className='btn-common color-common'>
                                <img src="\assets\telegrambtnimg.svg" alt="img" className="telegram" />
                                Launch Telegram
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Joincommunity