import React from 'react'
import "./metamask.scss"
const Metamask = () => {
    return (
        <>
            <section className='main-metamask' id='metamask'>
                <div className='theme-container'>
                    <div className='row'>
                        <div className='col-md-4 p-0'>
                            <div className='parent wow animate__animated animate__fadeInLeft animate__fast'>
                                <div className='imgcoin'>
                                    <img src='\assets\Group427321880.svg' alt='img' className='img-fluid' />
                                </div>
                                <div className='main-head'>
                                    <h3>Create a Metamask or Trust Wallet!</h3>
                                    {/* <p>Download and install the Metamask extension at here <a style={{color: "#FF3701"}} href="https://metamask.io/download/" target="_blank">https://metamask.io/download/</a> , then create a new wallet</p> */}
                                    <p>Create a new wallet or import an existing one! Remember to keep your keyphrase safe!</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 p-0'>
                            <div className='yellow wow animate__animated animate__fadeInUp animate__fast'>
                                <div className='imgcoin'>
                                    <img src='\assets\Group427321880.svg' alt='img' className='img-fluid' />
                                </div>
                                <div className='main-head'>
                                    <h3>Set on the BSC network!</h3>
                                    {/* <p>In Metamask, set the Binance Smart Chain (BSC) network;  Metamask to Binance Smart Chain ( <a style={{color: "#FF3701", wordBreak: "break-all"}} href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain">https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain</a> )</p> */}
                                    <p>Configure your Metamask wallet to connect with the Binance Smart Chain (BSC) network.</p>
                                    <div className='joinbutton'>
                                        <a href='https://www.pinksale.finance/launchpad/0xf78d841919a97a559c283ad8e22b38d1f087a408?chain=BSC' target="_blank">Join The Presale</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 p-0' style={{overflow: "hidden"}}>
                            <div className='parent1 wow animate__animated animate__fadeInRight animate__fast' >
                                <div className='imgcoin'>
                                    <img src='\assets\Group427321880.svg' alt='img' className='img-fluid' />
                                </div>
                                <div className='main-head'>
                                    <h3>Join The Presale</h3>
                                    <p>Visit our <a href="https://www.pinksale.finance/launchpad/0xf78d841919a97a559c283ad8e22b38d1f087a408?chain=BSC" style={{color: "#F23D0C"}}>Pinksale</a>  page, connect your Metamask or Trust Wallet, and hop on board.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Metamask