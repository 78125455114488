import React from 'react'
import './roadmap.scss'

const Roadmap = () => {
  return (
    <>
      <section className='roadmap' id='roadmap'>
        <img src="\assets\bg-shadow\roadmap-left.png" alt="img" className='img-fluid roadmap-left' />
        <img src="\assets\bg-shadow\roadmap-right.png" alt="img" className='img-fluid roadmap-right' />
        <img src="\assets\bg-shadow\roadmap-mobile1.png" alt="img" className='img-fluid roadmap1 d-none' />
        <img src="\assets\bg-shadow\roadmap-mobile2.png" alt="img" className='img-fluid roadmap2 d-none' />
        <img src="\assets\bg-shadow\roadmap-mobile3.png" alt="img" className='img-fluid roadmap3 d-none' />
        <img src="\assets\bg-shadow\roadmap-mobile4.png" alt="img" className='img-fluid roadmap4 d-none' />
        <img src="\assets\bg-shadow\roadmap-mobile5.png" alt="img" className='img-fluid roadmap5 d-none' />
        <div className="theme-container">
          <div className='wow animate__animated animate__fadeInUpBig animate__fast' >
            <h1 className="roadmaphead">Roadmap</h1>
            <p className="roadmappara">Explore the Kingkong Doge's high-level roadmap right here!</p>
          </div>
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-lg-3 col-md-6 col-sm-12 text-center desktop-margin wow animate__animated animate__fadeInLeftBig animate__fast" >
              <div className="parent-img">
                <img src="\assets\roadmap_phase1.png" alt="phase1" className="upperimg img-fluid" />
                <div className="inner-text inner-text1">
                  <h6>Phase 1</h6>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Concept Development</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Release of Whitepaper</p>
                  </div>

                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Development of a Comprehensive Marketing Plan</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Extensive Marketing Campaigns</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Application and Review Audits</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>KingKong Doge Public PreSale</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Listing on Decentralized Exchange (PancakeSwap)</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Listing on Coinmarketcap & Coingecko</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Frequent Buyback and Burn Events</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Lbank Exchange Listing</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>KingKong Doge Swap and Audit</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>KingKong Doge Cross-Chain Integration</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Purchases on popular sites like Walmart or eBay with KingKong Doge token</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 text-center desktop-margin wow animate__animated animate__fadeInRightBig animate__fast" >
            <div className="parent-img bottomside">
                <img src="\assets\roadmap_phase2.png" alt="phase1" className="upperimg img-fluid" />
                <div className="inner-text inner-text2">
                  <h6>Phase 2</h6>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Listing on Decentralized Exchange UniSwap</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Integration with Credit Card Payment Services</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>KingKong Doge Token Burn Platform</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Kingkong DAO </p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>KingKong Doge AI NFT dApp</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Enhanced Token Burning Process on the Swap</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>KingKong Doge NFT Collection</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>KingKong Doge NFTs Presale</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Expansion of Partnerships</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Amplified Marketing Efforts</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Forthcoming Exchange Listings</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Pending Decentralized Exchange Listings</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 text-center wow animate__animated animate__fadeInLeftBig animate__fast" >
            <div className="parent-img">
                <img src="\assets\roadmap_phase3.png" alt="phase1" className="upperimg img-fluid" />
                <div className="inner-text inner-text3">
                  <h6>Phase 3</h6>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Significant Influencer Marketing Initiative</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Major Announcement of KingKong Doge’s Eco-System</p>
                  </div>

                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Introduction of a New Token to be Incorporated into KingKong Doge</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Enhancement of KingKong Doge with Increased Token Burns through Integration</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>KingKong Doge Swap Version 2.0</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 text-center wow animate__animated animate__fadeInRightBig animate__fast" >
            <div className="parent-img bottomside">
                <img src="\assets\roadmap_phase4.png" alt="phase1" className="upperimg img-fluid" />
                <div className="inner-text inner-text4">
                  <h6>Phase 4</h6>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Expansion of KingKong Doge NFT Utilization</p>
                  </div>

                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Website Revamp</p>
                  </div>
                  <div className="text-para">
                    <img src="\assets\dot-yellow.svg" alt="img" className='img-fluid me-2' />
                    <p>Unveiling of the Revised Roadmap</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Roadmap