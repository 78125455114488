import "./navbar.scss";
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from "react";
const Navbar = () => {
  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;


  const [showsidebar, setShowSidebar] = useState(false);

 
  const handleShowSidebar = () => setShowSidebar(true);
  const handleClose = () => setShowSidebar(false);


  const handleClick = (e) => {
    setShowSidebar(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = '#countdown';
  }, 1000)
  }
  const handleClick1 = (e) => {
    setShowSidebar(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = '#feature';
  }, 1000)
  }
  const handleClick2 = (e) => {
    setShowSidebar(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = '#roadmap';
  }, 1000)
  }
  const handleClick3 = (e) => {
    setShowSidebar(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = '#utility';
  }, 1000)
  }
  const handleClick4 = (e) => {
    setShowSidebar(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = '#faqs';
  }, 1000)
  }
  
  
  return (
    <>
      <section className="main-navbar">
        <div class="theme-container">
          <nav class="navbar navbar-expand-xl">
            <a class="navbar-brand" href="/"><img style={{width: "100px"}} src="\logonew.png" alt="logo" className="logo" /> <p>KingKong Doge</p></a>
            <div className="audit-btn d-none">
              <a href="https://solidproof.io/" target="_blank"><img src="\assets\audit.png" alt="img" className="img-fluid" /></a>
            </div>
            <button class="navbar-toggler" onClick={handleShowSidebar}>
              <span><img src="\assets\bar.svg" alt="img" className="img-fluid" /></span>
            </button>
            <div class="collapse navbar-collapse mobile-none" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <HashLink to="#countdown" class="nav-link" style={
                    isActive("#countdown")
                      ? {
                        color: "#F6BB08",
                      }
                      : {}
                  }> <a>Pre-Sale</a> <img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link d-none" /></HashLink>
                 {
                  isActive("#countdown") ?  <img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link" /> : ""
                 }
                </li>
                <li class="nav-item">
                  <HashLink to="#feature" class="nav-link" style={
                    isActive("#feature")
                      ? {
                        color: "#F6BB08"
                      }
                      : {}
                  }><a >How to buy</a><img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link d-none" /></HashLink>
                  {
                  isActive("#feature") ? <img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link" /> : ""
                 }
                </li>
                <li class="nav-item">
                  <HashLink to="#roadmap" class="nav-link" style={
                    isActive("#roadmap")
                      ? {
                        color: "#F6BB08"
                      }
                      : {}
                  }> <a>Roadmap</a><img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link d-none" /></HashLink>
                  {
                  isActive("#roadmap") ? <img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link" /> : ""
                 }
                </li>
                <li class="nav-item">
                  <HashLink class="nav-link" to="#utility" style={
                    isActive("#utility")
                      ? {
                        color: "#F6BB08"
                      }
                      : {}
                  }><a>Utility</a><img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link scscscsc d-none" /></HashLink>
                  {
                  isActive("#utility") ? <img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link border-set-link2" /> : ""
                 }
                </li>
                <li class="nav-item">
                  <HashLink class="nav-link" to="#faqs" style={
                    isActive("#faqs")
                      ? {
                        color: "#F6BB08"
                      }
                      : {}
                  }> <a >FAQ’s</a><img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link scscscsc d-none" /></HashLink>
                   {
                  isActive("#faqs") ? <img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link border-set-link2" /> : ""
                 }
                </li>
                <li class="nav-item">
                   <a class="nav-link" href="http://swap.kingkongdoge.io/" target="_blank">Swap <img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link scscscsc d-none" /></a>
                </li>
                <li class="nav-item">
                   <a class="nav-link" href="https://dao.kingkongdoge.io/" target="_blank">DAO <img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link scscscsc d-none" /></a>
                </li>
                <li class="nav-item">
                   <a class="nav-link" href="https://ai.kingkongdoge.io/" target="_blank">AI NFT Dapp <img src="\assets\nav-border.svg" alt="img" className="img-fluid border-set-link d-none" /></a>
                </li>
              </ul>
              <form class="d-flex tusfssfsuxfufsu">
                <a href="https://app.solidproof.io/projects/kingkongdoge" target="_blank" className="btn-brdr m-0">
                  <img src="\assets\audit.png" alt="img" className="lightimg" />
                  Audit
                </a>
                <a href="#countdown1"  className="btn-common color-common">
                  <img src="\assets\cart.svg" alt="img" className="darkimg" />
                  How To Buy
                </a>
              </form>
            </div>
          </nav>
        </div>
      </section>
      <div className="mobile-menu">
      <Offcanvas placement="top"  show={showsidebar} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <a class="navbar-brand" href="#"><img src="\logo.png" alt="logo" className="logo" /> KingKong Doge</a> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <HashLink onClick={handleClick} to="#countdown" class="nav-link" style={
                    isActive("#countdown")
                      ? {
                        color: "#F6BB08"
                      }
                      : {}
                  }> <a>Pre-Sale</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink onClick={handleClick1} to="#feature" class="nav-link" style={
                    isActive("#feature")
                      ? {
                        color: "#F6BB08"
                      }
                      : {}
                  }><a >How to buy</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink onClick={handleClick2} to="#roadmap" class="nav-link" style={
                    isActive("#roadmap")
                      ? {
                        color: "#F6BB08"
                      }
                      : {}
                  }> <a>Roadmap</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink onClick={handleClick3} class="nav-link" to="#utility" style={
                    isActive("#utility")
                      ? {
                        color: "#F6BB08"
                      }
                      : {}
                  }><a>Utility</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink onClick={handleClick4} class="nav-link" to="#faqs" style={
                    isActive("#faqs")
                      ? {
                        color: "#F6BB08"
                      }
                      : {}
                  }> <a >FAQ’s</a></HashLink>
                </li>
                <li class="nav-item">
                   <a class="nav-link" href="http://swap.kingkongdoge.io/" target="_blank">Swap</a>
                </li>
                <li class="nav-item">
                   <a class="nav-link" href="https://dao.kingkongdoge.io/" target="_blank">DAO</a>
                </li>
                <li class="nav-item">
                   <a class="nav-link" href="https://ai.kingkongdoge.io/" target="_blank">AI NFT Dapp</a>
                </li>
              </ul>
              <form class="d-flex tusfssfsuxfufsu">
                <a href="https://app.solidproof.io/projects/kingkongdoge" className="btn-brdr m-0">
                  <img src="\assets\audit.png" alt="img" className="lightimg" />
                  Audit
                </a>
                <a href="#countdown1"  className="btn-common color-common">
                  <img src="\assets\cart.svg" alt="img" className="darkimg" />
                  How To Buy
                </a>
              </form>
        </Offcanvas.Body>
      </Offcanvas>
      </div>
    </>
  );
};

export default Navbar;
