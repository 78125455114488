import React, { useEffect, useState } from 'react';
import './footer.scss';
const Footer = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    return (
        <>
            <section className="main-footer">
               <img src="\assets\bg-shadow\footershademobile.png" alt="img" className='img-fluid footer-shade d-none' />
                <div className="theme-container">
                    <div className="row">
                        <div className="footers">
                            <div className="text">
                                <div className="innertext">
                                    <img style={{width: "100px"}} src="\logonew.png" alt="logo" className="logo" />
                                    <h6>KingKong Doge</h6>
                                </div>
                                <p className="para">
                                The KingKong Doge ecosystem is home to a community-driven platform with built-in tax-burning features, designed to promote sustainable growth and stability.
                                </p>
                            </div>
                            <div className="icons">
                               <a href="https://twitter.com/kingkongdoge" target="_blank"> <img src="\assets\twitternothover.svg" alt="img" className="socialicon ifnothovericons" /> <img src="\assets\twitter.svg" alt="img" className="socialicon d-none ifhovericons" /></a>
                                <a href="https://github.com/kingkongdoge" target="_blank"><img src="\assets\discordnothover.svg" alt="img" className="socialicon ifnothovericons" /> <img src="\assets\github.png" alt="img" className="socialicon d-none ifhovericons" /></a>
                               <a href="https://t.me/kingkongdogetoken" target="_blank"> <img src="\assets\telegramnothover.svg" alt="img" className="socialicon ifnothovericons" /> <img src="\assets\telegram.png" alt="img" className="socialicon d-none ifhovericons" /></a>
                               <a href="https://kingkongdoge.gitbook.io/" target="_blank"> <img src="\assets\gitbooknothover.svg" alt="img" className="socialicon ifnothovericons" /> <img src="\assets\gitbook.svg" alt="img" className="socialicon d-none ifhovericons" /></a>
                            </div>
                          
                        </div>
                    </div>
                    <div className="bottom-para">
                <p>Copyright © 2023 KingKong Doge Token</p>
            </div>
                </div>
                <img src="\assets\footergradient.png" alt="img" className="gradient img-fluid" />
                {showTopBtn && (
                    <a className='topup-btn' type="button" data-toggle="tooltip" data-placement="top" title="Back to Top" onClick={() => window.scrollTo(0, 0)}><img src="\assets\topup-btn.svg" alt="img" className='img-fluid' /></a>
                )}
                
            </section>
           
        </>
    )
}

export default Footer;
