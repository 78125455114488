import React from 'react'
import './nftdapp.scss'

const Nftdapp = () => {
  return (
    <>
      <section className='nftdapp'>
      <img src="\assets\nftdappleftimg.png"  className='nftdappleftblur' />
        <div className="theme-container">
          <div className="row fordirection">
            <div className="col-lg-5 col-md-5 col-sm-12 m-auto">
              <div className="mainnftdappimg">
                <img src="\assets\nptdapp.png" alt="innernftdappimg" className="innernftdappimg img-fluid" />
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="maintextnftdapp">
                <h1 className="nftdapphead">KingKong Doge AI NFT Minting dApp</h1>
                <p className="nftdapppara">Create & Mint your own AI NFTs using game-changing KingKong Doge AI NFT Minting dApp! </p>
               <a href="https://ai.kingkongdoge.io/" target="_blank"> <button className="btn-brdr">Learn More</button></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Nftdapp
