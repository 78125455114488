import React, {useState} from "react";
import "./banner.scss";
const Banner = () => {

  const [timeshow, settimeshow] = useState(false);
  // console.log("difference we get here is ", timeshow)
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  var time = new Date(1680616800 * 1000);
  // console.log("time today we have", time)
  function timer() {
    var now = new Date()
    var diff = time.getTime() - now.getTime()
    if (diff <= 0) {
      settimeshow(true)
      return;
    }
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    var hours = Math.floor(diff / (1000 * 60 * 60));
    var mins = Math.floor(diff / (1000 * 60));
    var secs = Math.floor(diff / 1000);
    var d = days;
    var h = hours - days * 24;
    var m = mins - hours * 60;
    var s = secs - mins * 60;
    setDay(d);
    setHour(h);
    setMin(m);
    setSec(s)
  }
  setInterval(() => {
    timer()
  }, 1000);

  return (
    <>
      <section className="main-banner" id="banner">
      <img src="\assets\Kingkong-bg-text.svg" alt="img" className="img-fluid kingkong-bg-text" />
      <span className="topblur"></span>
      <img src="\assets\bg-shadow\banner-left.png" alt="img" className="img-fluid leftblur" />
      <img src="\assets\bg-shadow\banner-right.png" alt="img" className="img-fluid rightblur" />
      <img src="\assets\bg-shadow\banner-left-mob.png" alt="img" className="img-fluid mobileleftblur d-none" />
      <img src="\assets\bg-shadow\banner-right-mob.png" alt="img" className="img-fluid mobilerightblur d-none" />
      <img src="\assets\bannerleftcircle.png" alt="circle" className="bannerleftcircle img-fluid" />
      <img src="\assets\bannerrightcircle.png" alt="circle" className="bannerrighttcircle img-fluid" />
        <div className="theme-container" >
          <div className="row">
            <div className="col-sm-12">
             <div style={{overflow: "hidden"}}>
             <h1 className="bannerhead wow animate__animated animate__fadeInDown" data-wow-duration="2s" data-wow-delay="3s">Discover <span className="gradeint-text"> KingKong DOGE  </span> - the Token with the Most Advanced Burning Mechanism! </h1>
              <div className="innerbanner">
                {/* <p className="innerpara wow animate__animated animate__fadeInLeft" data-wow-duration="2s" data-wow-delay="3s">
                Follow these steps, watch the video or join our <a target="_blank" href="https://t.me/kingkongdogetoken" style={{color:"#FF3701"}}>Telegram</a> group if you need any help

                </p> */}
                <div className="innerbtns wow animate__animated animate__fadeInRight" data-wow-duration="2s" data-wow-delay="3s" >
                  <a href="#feature"  className="btn-common color-common">
                    <img src="\assets\cart.svg" alt="img" className="cart" />
                    How To Buy
                  </a>
                  <a href="../Whitepaper.pdf" target="_blank" className="btn-brdr">
                    <img src="\assets\whitepaper.svg" alt="img" className="cart" />
                    Whitepaper
                  </a>
                </div>
              </div>
             </div>
              <div className="imgtimer" id="countdown">
              <span className="monkeyblur"></span>
              <div className="bannerimg wow animate__animated animate__zoomInDown" data-wow-duration="2s" data-wow-delay="3s">
                <img loading="lazy" src="\assets\fire.png" alt="bannerimg" className="monkey" />
              </div>
              <div className="timerbox" id="countdown1">
              {timeshow == false ? ( <>
                <h6 className="timerhead">Presale Start Time</h6>
                <div className="timerspans">
                <div className="innertimer">
                  <span className="innertimertext">{day ? day : 0}</span>
                  <p className="innertimepara">Days</p>
                </div>
                <div className="innertimer">
                  <span className="innertimertext">{hour ? hour : 0}</span>
                  <p className="innertimepara">Hours</p>
                </div>
                <div className="innertimer">
                  <span className="innertimertext">{min ? min : 0}</span>
                  <p className="innertimepara">Minutes</p>
                </div>
                <div className="innertimer">
                  <span className="innertimertext">{sec ? sec : 0}</span>
                  <p className="innertimepara">Second</p>
                </div>
                </div>
                <a href="https://www.pinksale.finance/launchpad/0xf78d841919a97a559c283ad8e22b38d1f087a408?chain=BSC" target="_blank" className="timerbtn btn-common color-common">Join The Presale</a>
                </>)
                :
                <>
                <h6 className="timerhead">Presale Is Live</h6>
                <a href="https://www.pinksale.finance/launchpad/0xf78d841919a97a559c283ad8e22b38d1f087a408?chain=BSC" target="_blank" className="timerbtn btn-common color-common">Join The Presale</a>
                </>
              }
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
