import web3NoAccount from './web3'
import TimerContractAbi from "./TimerContractAbi.json"

const getContract = (abi, address, web3) => {
    const _web3 = web3 ?? web3NoAccount;
    return new _web3.eth.Contract(abi, address)
}

export const timerContract = (address,web3) =>{
    return getContract(TimerContractAbi, address, web3)
}