import {timerContract} from "../../utils/contractHelpers";
import useWeb3 from "../useWeb3";
import { useCallback } from "react";
import Environment from "../../utils/environment";

export const AccuSupplyTB =()=>{
    // const {account} = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.TimedBurnContract;
    const contract = timerContract(contractAddress,web3);
    const AccuBurn = useCallback (
        async() =>{
            console.log("ssssssdadd")
            try {
                const balance = await contract.methods.getBurnerBalance().call()
                const newBal= web3.utils.fromWei(balance, "ether")
                console.log('3123213213', newBal)
                // return console.log('321312312332', await contract.methods)
                return newBal
            } catch (error) {
                console.log('uuuuuuuuuuuuuuuuu', error)
                throw error;
            }
        },[contract]
    );
    return { AccuBurn: AccuBurn };
}
export default AccuSupplyTB;
