import React from 'react'
import './dao.scss'

const Dao = () => {
    return (
        <>
            <section className='dao'>
                <div className="theme-container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="maintextdao">
                                <h1 className="daohead">KingKong DAO: Revolutionizing the future through community governance!</h1>
                                <p className="daopara">A community-driven Decentralized Autonomous Organization with a vision to make its community flourish in a democratic way through common consent. </p>
                                {/* <p className="daopara">And the best is yet to come!</p> */}
                                <a href="https://dao.kingkongdoge.io/" target="_blank"><button className="btn-brdr">Learn More</button></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 m-auto">
                            <div className="maindaoimg">
                                <img src="\assets\dao.png" alt="innerdaoimg" className="innerdaoimg img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dao
