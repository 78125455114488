import React, { useState } from 'react'
import "./feauture.scss"
import ModalVideo from 'react-modal-video'
const Feauture = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <>
            <section className='main-feauture'>
                <div className='theme-container'>
                    <div className='row'>
                        <div className='col-md-7' >
                            <div className='mainhead wow animate__animated animate__fadeInLeft animate__fast' >
                                <h4 id='feature'>
                                    
                                </h4>
                                <h2>Ready to join the KingKong Doge craze? Here's your guide to buying tokens during the presale!</h2>
                                <p> Follow our easy steps, check out our video tutorial, or join our supportive <a target="_blank" href="https://t.me/kingkongdogetoken" style={{color:"#FF3701"}}>Telegram</a> community for assistance.</p>
                            </div>
                        </div>
                        <div className='col-md-5' style={{overflow: "hidden"}}>
                            <div className='boxbutton wow animate__animated animate__fadeInRight animate__fast' >
                                <div className='imagebox'>
                                    <img src='\assets\Group44.svg' alt='img' className='img-fluid' />
                                   <a onClick={()=> setOpen(true)} className='play-btn'> <img src="\assets\play-btn.svg" alt="img" className='img-fluid ' /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <React.Fragment>
			<ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="rtgNAQZSSs0" onClose={() => setOpen(false)} />
		</React.Fragment>
        </>
    )
}

export default Feauture