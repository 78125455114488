import React from 'react'
import "./about.scss"
const About = () => {
    return (
        <>
            <section className='main-about' id='about'>
                <img src="\assets\kingkong-side-bg.png" alt="img" className='img-fluid kinkong-bbg-right' />
                <img src="\assets\bg-shadow\unleash-left.png" alt="img" className='img-fluid about-left' />
                <img src="\assets\bg-shadow\unleash-right.png" alt="img" className='img-fluid about-right' />
                <img src="\assets\bg-shadow\unleash-left-mobile.png" alt="img" className='img-fluid about-left-mobile d-none' />
                <div className='theme-container'>
                    {/* <div className='row'>
                        <div className='col-sm-12 padd-sm' style={{overflow: "hidden"}}>
                            <div className='headabout wow animate__fadeInRight animate__fast' >
                                <h3>ABOUT</h3>
                                <h2 style={{maxWidth: "700px"}}>KingKong Doge: <span> Taking </span> you to the <span>moon!</span>   </h2>
                            </div>
                        </div>
                    </div> */}
                    <div className='row' >
                        <div className='col-md-6 padd-sm order2-sm'>
                            <div className='mainimage wow animate__animated animate__fadeInLeft animate__slow' >
                                <img src='\gif\kingkongdoge.gif' alt='img' className='img-fluid men-fire' />
                                <img src="\assets\aboutsection-stand1.png" alt="img" className='img-fluid stand-about' />
                            </div>

                        </div>
                        <div className='col-md-6 mt-auto padd-sm tuuyctctcc ' style={{ overflow: "hidden" }}>
                            <div className='head headabout wow animate__animated animate__bounceInRight  animate__fast' >
                                <h3>ABOUT</h3>
                                {/* <h2>KingKong Doge: <span> Taking </span> you to the <span>moon!</span>   </h2> */}
                                <p>Taking the jungle by storm, welcome to the universe of KingKong Doge Token!</p>
                                <p>From the magnanimous feature of buyback & burn mechanism to AI NFTs
                                    minting, swap, DAO, Farms & pools of liquidity, and ever-evolving web3, KingKong Doge is taking you to
                                    the moon!</p>
                                <p className='grow'>  To explore more, fasten your seatbelts tight & let's go!</p>
                                <div className='learn'>
                                    <a href=" https://kingkongdoge.gitbook.io/kingkongdoge/" target="_blank" className='btn-brdr'>Learn More</a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default About