import React, { useState } from 'react'
import './partners.scss'
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";



const Partners = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const settings = {
    dots:false,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: false,
    cssEase: 'linear',
    enableMomentum: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          
        }
      }
    ]
  };

  return (
    <>
      <section className="partners" id='partners'>
        <img src='\assets\bg-shadow\partner-shade.png' alt='img' className='gradient-utilityb img-fluid' />
        <img src="\assets\bg-shadow\partner-mobile.png" alt="img" className='img-fluid partner-left d-none' />
        <div style={{ overflow: "hidden" }}>
          <h3 className="partnerhead wow animate__animated animate__fadeInDownBig animate__fastest" >Featured On</h3>
          <div class="marquee">
            <div class="marquee__group">
              {/* <a href="https://www.digitaljournal.com/" target="_blank"> <img src="\assets\featuredon\logo1.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a> */}
              {/* <a href="https://www.entrepreneur.com/" target="_blank"><img src="\assets\featuredon\logo2.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a> */}
              {/* <a href="https://www.forbes.com/?sh=359f80952254" target="_blank"><img src="\assets\featuredon\logo3.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a> */}
              <a href="https://finance.yahoo.com/news/introducing-kingkong-doge-future-meme-160600361.html" target="_blank"><img src="\assets\featuredon\logo4.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
              {/* <a href="https://cointelegraph.com/" target="_blank"><img src="\assets\featuredon\logo5.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a> */}
              <a href="https://www.bloomberg.com/press-releases/2023-03-30/introducing-kingkong-doge-the-future-of-meme-tokens" target="_blank"><img src="\assets\featuredon\logo6.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
              <a href="https://www.marketwatch.com/press-release/introducing-kingkong-doge-the-future-of-meme-tokens-2023-03-30?mod=search_headline" target="_blank"><img src="\assets\featuredon\logo7.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
              {/* <a href="https://www.nasdaq.com/" target="_blank"><img src="\assets\featuredon\logo8.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a> */}
              <a href="https://benzinga.com/pressreleases/23/03/g31588487/introducing-kingkong-doge-the-future-of-meme-tokens" target="_blank"><img src="\assets\featuredon\logo9.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
            </div>

            <div aria-hidden="true" class="marquee__group">
              {/* <a href="https://www.digitaljournal.com/" target="_blank"> <img src="\assets\featuredon\logo1.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a> */}
              {/* <a href="https://www.entrepreneur.com/" target="_blank"><img src="\assets\featuredon\logo2.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a> */}
              {/* <a href="https://www.forbes.com/?sh=359f80952254" target="_blank"><img src="\assets\featuredon\logo3.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a> */}
              <a href="https://finance.yahoo.com/news/introducing-kingkong-doge-future-meme-160600361.html" target="_blank"><img src="\assets\featuredon\logo4.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
              {/* <a href="https://cointelegraph.com/" target="_blank"><img src="\assets\featuredon\logo5.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a> */}
              <a href="https://www.bloomberg.com/press-releases/2023-03-30/introducing-kingkong-doge-the-future-of-meme-tokens" target="_blank"><img src="\assets\featuredon\logo6.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
              <a href="https://www.marketwatch.com/press-release/introducing-kingkong-doge-the-future-of-meme-tokens-2023-03-30?mod=search_headline" target="_blank"><img src="\assets\featuredon\logo7.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
              {/* <a href="https://www.nasdaq.com/" target="_blank"><img src="\assets\featuredon\logo8.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a> */}
              <a href="https://benzinga.com/pressreleases/23/03/g31588487/introducing-kingkong-doge-the-future-of-meme-tokens" target="_blank"><img src="\assets\featuredon\logo9.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
            </div>
          </div>

        </div>
        <div className='exchange-logos' style={{ overflow: "hidden" }}>
          <h3 className="partnerhead wow animate__animated animate__fadeInDownBig animate__fastest" >Exchanges & Partners</h3>
          <div class="new-design">
            <button className='view-btn' onClick={handleShow}>View all</button>
            {/* <div class="marquee__group">
          <a href="https://app.multichain.org/" target="_blank"><img src="\assets\exchangeandpartners\multichain.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://solidproof.io/" target="_blank"><img src="\assets\exchangeandpartners\solidproof.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://twitter.com/bsc_daily" target="_blank"><img src="\assets\exchangeandpartners\bscdaily.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://www.bsc.news/post/kingkong-doge-a-revolutionary-project-that-aims-to-transform-the-meme-token-landscape-on-bnb-chain-ethereum" target="_blank"><img src="\assets\exchangeandpartners\bscnews.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://twitter.com/shopping_io/status/1640705242995257346?s=20" target="_blank"><img src="\assets\exchangeandpartners\shoppingio.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://www.pinksale.finance/" target="_blank"><img src="\assets\exchangeandpartners\pinksale.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://bsctimes.com/kingkong-doge-plans-to-make-a-splash-in-the-crypto-world/" target="_blank"><img src="\assets\exchangeandpartners\bsctimes.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://coinwire.com/discover-kingkong-doge-the-token-with-the-most-advanced-burning-method/#Introduction" target="_blank"><img src="\assets\exchangeandpartners\coinware.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://bnbswap.ventures/" target="_blank"><img src="\assets\exchangeandpartners\bnbswap.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://poocoin.app/" target="_blank"><img src="\assets\exchangeandpartners\poocoin.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://www.dextools.io/app/en" target="_blank"><img src="\assets\exchangeandpartners\dextools.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://www.dexview.com/" target="_blank"><img src="\assets\exchangeandpartners\dexview.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://bscscan.com/" target="_blank"><img src="\assets\exchangeandpartners\bscscan.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://pancakeswap.finance/" target="_blank"><img src="\assets\exchangeandpartners\pancakeswap.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://www.coingecko.com/" target="_blank"><img src="\assets\exchangeandpartners\coingecko.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://coinmarketcap.com/" target="_blank"><img src="\assets\exchangeandpartners\coinmarketcap.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://arbitrum.io/" target="_blank"><img src="\assets\exchangeandpartners\arbitrum.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://www.sushi.com/" target="_blank"><img src="\assets\exchangeandpartners\sushi.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
          <a href="https://support.lbank.site/hc/en-gb/articles/16943509231257" target="_blank"><img src="\assets\exchangeandpartners\lbank.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a>
        </div> */}
 <Slider {...settings}>
              <div>  <a href="https://app.multichain.org/" target="_blank"><img src="\assets\exchangeandpartners\multichain.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div>  <a href="https://app.solidproof.io/projects/kingkongdoge/" target="_blank"><img src="\assets\exchangeandpartners\solidproof.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div> <a href="https://twitter.com/bsc_daily" target="_blank"><img src="\assets\exchangeandpartners\bscdaily.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div><a href="https://www.bsc.news/post/kingkong-doge-a-revolutionary-project-that-aims-to-transform-the-meme-token-landscape-on-bnb-chain-ethereum" target="_blank"><img src="\assets\exchangeandpartners\bscnews.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div> <a href="https://twitter.com/shopping_io/status/1640705242995257346?s=20" target="_blank"><img src="\assets\exchangeandpartners\shoppingio.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div> <a href="https://www.dexview.com/bsc/0x577C45921407E8aab950b073070c392c7BD07cdB" target="_blank"><img src="\assets\exchangeandpartners\dexview.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div><a href="https://www.pinksale.finance/launchpad/0xf78d841919a97a559c283ad8e22b38d1f087a408?chain=BSC" target="_blank"><img src="\assets\exchangeandpartners\pinksale.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div> <a href="https://bsctimes.com/kingkong-doge-plans-to-make-a-splash-in-the-crypto-world/" target="_blank"><img src="\assets\exchangeandpartners\bsctimes.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div> <a href="https://coinwire.com/discover-kingkong-doge-the-token-with-the-most-advanced-burning-method/#Introduction" target="_blank"><img src="\assets\exchangeandpartners\coinware.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              {/* <div><a href="https://bnbswap.ventures/" target="_blank"><img src="\assets\exchangeandpartners\bnbswap.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div> */}
              <div><a href="https://poocoin.app/tokens/0x577c45921407e8aab950b073070c392c7bd07cdb" target="_blank"><img src="\assets\exchangeandpartners\poocoin.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div>  <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0x577c45921407e8aab950b073070c392c7bd07cdb" target="_blank"><img src="\assets\exchangeandpartners\dextools.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div> <a href="https://bscscan.com/token/0x577c45921407e8aab950b073070c392c7bd07cdb/" target="_blank"><img src="\assets\exchangeandpartners\bscscan.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div><a href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x577C45921407E8aab950b073070c392c7BD07cdB" target="_blank"><img src="\assets\exchangeandpartners\pancakeswap.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div> <a href="https://www.coingecko.com/en/coins/kingkongdoge" target="_blank"><img src="\assets\exchangeandpartners\coingecko.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div> <a href="https://coinmarketcap.com/currencies/kingkongdoge/" target="_blank"><img src="\assets\exchangeandpartners\coinmarketcap.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div><a href="https://etherscan.io/token/0x577c45921407e8aab950b073070c392c7bd07cdb" target="_blank"><img src="\assets\exchangeandpartners\etherscan.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div>  <a href="https://uniswap.org/" target="_blank"><img src="\assets\exchangeandpartners\uniswap.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
              <div><a href="https://support.lbank.site/hc/en-gb/articles/16943509231257" target="_blank"><img src="\assets\exchangeandpartners\lbank.svg" alt="img" className='img-fluid wow animate__animated animate__fadeInLeft animate__slow' /></a></div>
            </Slider>
          
          </div>
        </div>
      </section>

     


      <Modal className='logoallshows' show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="inner-logos">
            <a target="_blank" href="https://app.multichain.org/"><img src="\assets\modallogos\logo1.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://app.solidproof.io/projects/kingkongdoge/"><img src="\assets\modallogos\logo2.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://twitter.com/bsc_daily"><img src="\assets\modallogos\logo3.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://www.bsc.news/post/kingkong-doge-a-revolutionary-project-that-aims-to-transform-the-meme-token-landscape-on-bnb-chain-ethereum"><img src="\assets\modallogos\logo4.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://twitter.com/shopping_io/status/1640705242995257346?s=20"><img src="\assets\modallogos\logo5.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://www.dexview.com/bsc/0x577C45921407E8aab950b073070c392c7BD07cdB"><img src="\assets\modallogos\logo12.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://www.pinksale.finance/launchpad/0xf78d841919a97a559c283ad8e22b38d1f087a408?chain=BSC"><img src="\assets\modallogos\logo6.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://bsctimes.com/kingkong-doge-plans-to-make-a-splash-in-the-crypto-world/"><img src="\assets\modallogos\logo7.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://coinwire.com/discover-kingkong-doge-the-token-with-the-most-advanced-burning-method/#Introduction"><img src="\assets\modallogos\logo8.svg" alt="img" className='img-fluid' /></a>
            {/* <a target="_blank" href="https://bnbswap.ventures/"><img src="\assets\modallogos\logo9.svg" alt="img" className='img-fluid' /></a> */}
            <a target="_blank" href="https://poocoin.app/tokens/0x577c45921407e8aab950b073070c392c7bd07cdb"><img src="\assets\modallogos\logo10.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://www.dextools.io/app/en/bnb/pair-explorer/0x577c45921407e8aab950b073070c392c7bd07cdb"><img src="\assets\modallogos\logo11.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://bscscan.com/token/0x577c45921407e8aab950b073070c392c7bd07cdb/"><img src="\assets\modallogos\logo13.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x577C45921407E8aab950b073070c392c7BD07cdB"><img src="\assets\modallogos\logo14.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://www.coingecko.com/en/coins/kingkongdoge"><img src="\assets\modallogos\logo15.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://coinmarketcap.com/currencies/kingkongdoge/"><img src="\assets\modallogos\logo16.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://uniswap.org/"><img src="\assets\modallogos\logo17.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://etherscan.io/token/0x577c45921407e8aab950b073070c392c7bd07cdb"><img src="\assets\modallogos\logo18.svg" alt="img" className='img-fluid' /></a>
            <a target="_blank" href="https://support.lbank.site/hc/en-gb/articles/16943509231257"><img src="\assets\modallogos\logo19.svg" alt="img" className='img-fluid' /></a>
          </div>
        </Modal.Body>
        <button className='close-btn' onClick={handleClose}>Close</button>
      </Modal>




    </>
  )
}

export default Partners