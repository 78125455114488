import React from 'react'
import { useState } from 'react'
import "./utility.scss"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


const active = { backgroundColor: '#F6BB08' }
const inactive = {}
const index = 0;

const Utility = () => {
    const [selected, setSelected] = useState(index);
    const handleClick = () => () => {
        if (selected <= 4) {
            setSelected(selected + 1);
        }
    };
    const handleClick1 = () => () => {
        if (selected >= 0) {
            setSelected(selected - 1);
        }
    };

    const owl_option = {
        loop: true,
        nav: true,
        dots: false,
        autoplay: false,
        margin: 10,
        navText: [
            "<img src='/assets/arrow-lefthover.svg' alt='img' className='img-fluid' />",
            "<img src='/assets/arrow-righthover.svg' alt='img' className='img-fluid' />",
        ],
        responsive: {
            0: {
                items: 1.1,
            },
            361: {
                items: 1.1,
            },
            600: {
                items: 1.2,
            }
        },
    };

    const [isShown, setIsShown] = useState(0);



    return (
        <>
            <div className="wrapper-utility" id='utility'>
                <section className="utility utility-desktop" >
                    <img src="\assets\bg-shadow\utility-left.png" alt="img" className='img-fluid utility-left' />
                    <img src="\assets\bg-shadow\utility-tablet.png" alt="img" className='img-fluid utility-tablet d-none' />
                    <div className="theme-container">
                        <div className="main-heading wow animate__animated animate__backInDown animate__fast">
                            <span className='sub-title'>Features</span>
                            <h5 className='title'>Powerfull Utility</h5>
                            <p className="para">
                                KingKong Doge packs a punch with a multitude of features, including the most impressive burn mechanism in the game! Let's dive deeper!
                            </p>
                        </div>
                        {
                            isShown == 0 ? <>
                                <div className="bottom-content" style={{ overflow: "hidden" }}>
                                    <div className="inner-card wow animate__animated animate__fadeInRight animate__fast" style={selected == 1 ? active : inactive} >
                                        <div className="left">
                                            {
                                                selected == 1 ? <>
                                                    <img src="\assets\anotherburnhover.svg" alt="img" className='img-fluid' />
                                                </> : <>
                                                    <img src="\assets\anotherburn.svg" alt="img" className='img-fluid ifnothover' />
                                                    <img src="\assets\anotherburnhover.svg" alt="img" className='img-fluid ifhover d-none' />
                                                </>
                                            }
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 1 ? "colorchangeh6" : "colornotchangeh6"}>Timed Burn</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 1 ? "colorchangep" : "colornotchangep"}>Tokens accumulated in the wallet are sent to the dead wallet and automatically burnt after 24 hours.</p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInLeft animate__fast" style={selected == 2 ? active : inactive} >
                                        <div className="left">
                                            {selected == 2 ? <>
                                                <img src="\assets\supplyburn-hover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\supplyburn.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\supplyburn-hover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 2 ? "colorchangeh6" : "colornotchangeh6"}>Supply Burn</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 2 ? "colorchangep" : "colornotchangep"}>Experience the true benefits of supply burn! Every purchase and sale results in a decrease of token supply, boosting demand for KingKong Doge. </p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInRight animate__fast" style={selected == 3 ? active : inactive} >
                                        <div className="left">
                                            {selected == 3 ? <>
                                                <img src="\assets\liquiditypool-hover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\liquiditypool.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\liquiditypool-hover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 3 ? "colorchangeh6" : "colornotchangeh6"}>Liquidity Pool</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 3 ? "colorchangep" : "colornotchangep"}>Liquidity pools drive our project forward! Tokens generated from these pools will be used to fuel development and support regular burns and buybacks. </p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInRight animate__fast" style={selected == 2 ? active : inactive} >
                                        <div className="left">
                                            {selected == 2 ? <>
                                                <img src="\assets\liquiditylockhover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\liquiditylock.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\liquiditylockhover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 2 ? "colorchangeh6" : "colornotchangeh6"}>Liquidity Lock</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 2 ? "colorchangep" : "colornotchangep"}>We're committed to building a strong, trustworthy community. To show our dedication, liquidity will remain locked for a solid 100 years.</p>
                                        </div>
                                    </div>
                                </div>
                            </> : ""
                        }
                        {
                            isShown == 1 ? <>
                                <div className="bottom-content" style={{ overflow: "hidden" }}>
                                    <div className="inner-card wow animate__animated animate__fadeInRight animate__fast" style={selected == 3 ? active : inactive} >
                                        <div className="left">
                                            {selected == 3 ? <>
                                                <img src="\assets\developmenthover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\developmentnothover.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\developmenthover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 3 ? "colorchangeh6" : "colornotchangeh6"}>Development</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 3 ? "colorchangep" : "colornotchangep"}>Leading the way to innovation and quality, Development tax will be directed to funds for further development.</p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInLeft animate__fast" style={selected == 4 ? active : inactive} >
                                        <div className="left">
                                            {selected == 4 ? <>
                                                <img src="\assets\marketinghover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\marketing.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\marketinghover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 4 ? "colorchangeh6" : "colornotchangeh6"}>Marketing</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 4 ? "colorchangep" : "colornotchangep"}>Be part of the future of KingKong Doge and help rule the jungle! A percentage of every transaction goes toward marketing the project. </p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInLeft animate__fast" style={selected == 4 ? active : inactive} >
                                        <div className="left">
                                            {selected == 4 ? <>
                                                <img src="\assets\surprisefeaturehover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\surprisefeature.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\surprisefeaturehover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 4 ? "colorchangeh6" : "colornotchangeh6"}>Coming Soon</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 4 ? "colorchangep" : "colornotchangep"}>Get ready to be surprised! Exciting new features are coming your way, so stay tuned. </p>
                                        </div>
                                    </div>
                                    <div className="inner-card mb-0 wow animate__animated animate__fadeInLeft animate__fast" style={selected == 4 ? active : inactive} >
                                        <div className="left">
                                            {selected == 4 ? <>
                                                <img src="\assets\surprisefeaturehover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\surprisefeature.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\surprisefeaturehover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 4 ? "colorchangeh6" : "colornotchangeh6"}>Coming Soon</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 4 ? "colorchangep" : "colornotchangep"}>Don't miss out on our latest innovation! Hold tight for the big reveal of another top-notch feature.</p>
                                        </div>
                                    </div>

                                </div>
                            </> : ""
                        }
                        <div className="bottom-navs">
                            <button onClick={() => setIsShown(0)} className={selected >= 1 ? "setopacity" : "setopacity"}><img src="\assets\arrow-left.svg" alt="img" className='img-fluid nothover' /><img src="\assets\arrow-lefthover.svg" alt="img" className='img-fluid ifhover d-none' /></button>
                            <button onClick={() => setIsShown(1)} className={selected == 4 ? "opacity5sec" : "setopacity"}><img src="\assets\arrow-right.svg" alt="img" className='img-fluid nothover' /><img src="\assets\arrow-righthover.svg" alt="img" className='img-fluid ifhover d-none' /></button>
                        </div>
                    </div>
                </section>
                <section className="utility utility-mobile d-none">
                    <img src="\assets\bg-shadow\utility-right-mob.png" alt="img" className='img-fluid utility-right-mobile d-none' />
                    <div className="theme-container pe-0">
                        <div className="main-heading">
                            <span className='sub-title'>Features</span>
                            <h5 className='title'>Powerfull Utility</h5>
                            <p className="para">
                                Holding up tonnage of features, KingKong Doge also has the best burn mechanism & let’s explore more!
                            </p>
                        </div>
                        <div className="bottom-content">
                            <div class="owl_option">
                                <OwlCarousel className="owl-theme" {...owl_option}>
                                    <div className="inner-card wow animate__animated animate__fadeInRight animate__fast" style={selected == 1 ? active : inactive} >
                                        <div className="left">
                                            {
                                                selected == 1 ? <>
                                                    <img src="\assets\anotherburnhover.svg" alt="img" className='img-fluid' />
                                                </> : <>
                                                    <img src="\assets\anotherburn.svg" alt="img" className='img-fluid ifnothover' />
                                                    <img src="\assets\anotherburnhover.svg" alt="img" className='img-fluid ifhover d-none' />
                                                </>
                                            }
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 1 ? "colorchangeh6" : "colornotchangeh6"}>Timed Burn</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 1 ? "colorchangep" : "colornotchangep"}>Tokens accumulated in the wallet are sent to the dead wallet and automatically burnt after 24 hours.</p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInLeft animate__fast" style={selected == 2 ? active : inactive} >
                                        <div className="left">
                                            {selected == 2 ? <>
                                                <img src="\assets\supplyburn-hover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\supplyburn.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\supplyburn-hover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 2 ? "colorchangeh6" : "colornotchangeh6"}>Supply Burn</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 2 ? "colorchangep" : "colornotchangep"}>Experience the true benefits of supply burn! Every purchase and sale results in a decrease of token supply, boosting demand for KingKong Doge. </p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInRight animate__fast" style={selected == 3 ? active : inactive} >
                                        <div className="left">
                                            {selected == 3 ? <>
                                                <img src="\assets\liquiditypool-hover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\liquiditypool.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\liquiditypool-hover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 3 ? "colorchangeh6" : "colornotchangeh6"}>Liquidity Pool</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 3 ? "colorchangep" : "colornotchangep"}>Liquidity pools drive our project forward! Tokens generated from these pools will be used to fuel development and support regular burns and buybacks. </p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInRight animate__fast" style={selected == 2 ? active : inactive} >
                                        <div className="left">
                                            {selected == 2 ? <>
                                                <img src="\assets\liquiditylockhover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\liquiditylock.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\liquiditylockhover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 2 ? "colorchangeh6" : "colornotchangeh6"}>Liquidity Lock</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 2 ? "colorchangep" : "colornotchangep"}>We're committed to building a strong, trustworthy community. To show our dedication, liquidity will remain locked for a solid 100 years.</p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInRight animate__fast" style={selected == 3 ? active : inactive} >
                                        <div className="left">
                                            {selected == 3 ? <>
                                                <img src="\assets\developmenthover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\developmentnothover.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\developmenthover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 3 ? "colorchangeh6" : "colornotchangeh6"}>Development</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 3 ? "colorchangep" : "colornotchangep"}>Leading the way to innovation and quality, Development tax will be directed to funds for further development.</p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInLeft animate__fast" style={selected == 4 ? active : inactive} >
                                        <div className="left">
                                            {selected == 4 ? <>
                                                <img src="\assets\marketinghover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\marketing.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\marketinghover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 4 ? "colorchangeh6" : "colornotchangeh6"}>Marketing</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 4 ? "colorchangep" : "colornotchangep"}>Be part of the future of KingKong Doge and help rule the jungle! A percentage of every transaction goes toward marketing the project. </p>
                                        </div>
                                    </div>
                                    <div className="inner-card wow animate__animated animate__fadeInLeft animate__fast" style={selected == 4 ? active : inactive} >
                                        <div className="left">
                                            {selected == 4 ? <>
                                                <img src="\assets\surprisefeaturehover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\surprisefeature.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\surprisefeaturehover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 4 ? "colorchangeh6" : "colornotchangeh6"}>Coming Soon</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 4 ? "colorchangep" : "colornotchangep"}>Get ready to be surprised! Exciting new features are coming your way, so stay tuned. </p>
                                        </div>
                                    </div>
                                    <div className="inner-card mb-0 wow animate__animated animate__fadeInLeft animate__fast" style={selected == 4 ? active : inactive} >
                                        <div className="left">
                                            {selected == 4 ? <>
                                                <img src="\assets\surprisefeaturehover.svg" alt="img" className='img-fluid' />
                                            </> : <>
                                                <img src="\assets\surprisefeature.svg" alt="img" className='img-fluid ifnothover' />
                                                <img src="\assets\surprisefeaturehover.svg" alt="img" className='img-fluid d-none ifhover' />
                                            </>}
                                        </div>
                                        <div className="mid">
                                            <h6 className={selected == 4 ? "colorchangeh6" : "colornotchangeh6"}>Coming Soon</h6>
                                        </div>
                                        <div className="right">
                                            <p className={selected == 4 ? "colorchangep" : "colornotchangep"}>Don't miss out on our latest innovation! Hold tight for the big reveal of another top-notch feature.</p>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                        {/* <div className="bottom-navs">
                        <button onClick={handleClick1(index)} className={selected >= 1 ? "setopacity" : "opacity5"}><img src='\assets\arrow-left.svg' alt='img' className='img-fluid' /></button>
                        <button onClick={handleClick(index)} className={selected == 4 ? "opacity5sec" : "setopacity"}><img src='\assets\arrow-right.svg' alt='img' className='img-fluid' /></button>
                    </div> */}
                    </div>
                </section>
            </div>
        </>
    )
}

export default Utility
