import {timerContract} from "../../utils/contractHelpers";
import useWeb3 from "../useWeb3";
import { useCallback } from "react";
import Environment from "../../utils/environment";

export const GetBurnedTime =()=>{
    // const {account} = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.TimedBurnContract;
    const contract = timerContract(contractAddress,web3);
    const timeBurn = useCallback (
        async() =>{
            console.log("ssssssdadd")
            try {
                const balance = await contract.methods.timedBurnTime().call()
                console.log('23432', balance)
                return console.log('321312312332', await contract.methods)
                // return balance
            } catch (error) {
                console.log('uuuuuuuuuuuuuuuuu', error)
                throw error;
            }
        },[contract]
    );
    return { timeBurn: timeBurn };
}
export default GetBurnedTime;
