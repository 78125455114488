
import '../../App.scss';
import React, { useEffect, useState } from "react";
import Banner from './main-banner/Banner';
import Joincommunity from './Joincommunity/Joincommunity';
import Feauture from '../Feauture/Feauture';
import Metamask from '../Metamask/Metamask';
import About from '../About/About';
import Utility from './Utility/Utility';
import Roadmap from './Roadmap/Roadmap';
import Faqs from './Faqs/Faqs';
import Partners from './Partners/Partners';
import Navbar from './header/Navbar';
import Burning from '../BurningToken/Burning';
import Loader from '../../hooks/loader';
import WOW from "wowjs";
import Dao from './Dao/Dao';
import Nftdapp from './Nftdapp/Nftdapp';
function Landing() {

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoader(false)
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect (() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0)
  },[])

  return (
    <>


     {loader && <Loader />}
       <Navbar />
      <Banner />
      <Feauture />
      <Metamask />
      <Utility />
      <About />
      <Dao />
      <Nftdapp />
      <Burning />
      <Roadmap />
      <Partners />
      <Faqs />
      <Joincommunity/>
    </>
  );
}

export default Landing;