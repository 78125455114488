import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Landing from './components/landing/Landing';
import Navbar from './components/landing/header/Navbar';
import Footer from './components/landing/footer/Footer';
import Burning from './components/BurningToken/Burning';
function App() {
  return (
    <>
      <Router>
        {/* <Navbar/> */}
        <Switch>
          <Route exact path='/' component={Landing} />
        </Switch>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
